import React from 'react'
import profileImage from '../../assets/profile/image.jpeg'

function About() {
    return (
        <section className="about">
            <h1>About <span className="title-style">me</span></h1>
            <div className="profile-img-container">
                <img src={profileImage} className="profile-image" alt="johann taylor"/>
                <div className="description">
                    <p className="greeting">Hi, I'm Johann!</p>
                    <p className="occupation">Front-end Web Developer</p>
                </div>

            </div>
            <div className="about-content">
                <p>I am a front-end web developer with background in piano pedagogy and education.
                    As a teacher and performer, I am constantly challenged to push my capacity for improvement and learning in order to be on top of my game. 
                    Pushing my capacity along with my passion and dedication have all contributed to the successes on my coding journey.
                </p>
                <p>
                My musical training has been a very important asset in my coding journey. 
                It has enabled me to continuously foster my critical thinking skills, attention to detail, as well as effectively communicating complex ideas in simpler ways. 
                I aspire to write quality code as I consider it synonymous with crafting an intricate musical composition.
                </p>
            </div>
        </section>
    );
}

export default About;